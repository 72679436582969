import React, { FC, useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../../context/ThemeContext/ThemeContext';
import styles from './Navbar.module.scss'
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import CustomSelect from "../CustomSelect/CustomSelect";
import classNames from 'classnames';
import { useAppDispatch } from '../../types/hooks';
import HeaderRoutingSelect from '../../ui/Selections/HeaderRoutingSelect/HeaderRoutingSelect';
import { LanguageContext } from '../../context/LanguageContext/LanguageContext';
import sportVariants from '../../localization/navbar/sportVariants.json'
import championships from '../../localization/navbar/championships.json'

import EPL from '../../assets/tournament_images/EPL.png'
import SERIA_A from '../../assets/tournament_images/SERIA_A.png'
import LA_LIGA from '../../assets/tournament_images/LA_LIGA.png'
import LIGA_1 from '../../assets/tournament_images/LIGA_1.png'
import BUNDESLIGA from '../../assets/tournament_images/BUNDESLIGA.png'
import EURO_2024 from '../../assets/tournament_images/EURO_2024.png'
import axios from 'axios';
import { GET_EURO_2024, GET_LA_LIGA_DATA, GET_PREMIER_LEAGUE_DATA } from '../../api/variables';
import { changeTournamentType, getTournamentData, setCurrentChampionshipIdAndSeason } from '../../redux/tournament-slice/tournament-slice';

interface Route {
    id: number,
    titleEng: string,
    titleRu: string
    path: string,
    img?: string,
    img_active?: string
}

interface Routes {
    routes: Route[]
}

interface Option {
    to: string;
    label: string;
}



const sportOptions = [
    { ruTitle: sportVariants.football.ru, engTitle: sportVariants.football.eng, path: '#' },
    { ruTitle: sportVariants.hockey.ru, engTitle: sportVariants.hockey.eng, path: '#' },
    { ruTitle: sportVariants.basketball.ru, engTitle: sportVariants.basketball.eng, path: '#' },
]

const Navbar: FC<Routes> = ({ routes }) => {

    const championshipsOptions = [
        {
            engTitle: championships.epl.eng,
            ruTitle: championships.epl.ru,
            path: 'chart/epl', 
            img: EPL, 
            tournamentPath: GET_PREMIER_LEAGUE_DATA,
            championshipId: '2694d35e-c157-4497-9957-56f4e93ab7bb',
            season: '2024-2025'
        },
        { 
            engTitle: 'EURO', 
            ruTitle: 'ЕВРО', 
            path: 'chart/euro-2024', 
            img: EURO_2024, 
            tournamentPath: GET_EURO_2024,
            championshipId: 'b6b0d1b3-87b0-4431-9305-7a4e377062bb',
            season: '2024'
        },
        { 
            engTitle: championships.la_liga.eng, 
            ruTitle: championships.la_liga.ru, 
            path: 'chart/la-liga', 
            img: LA_LIGA, 
            tournamentPath: GET_LA_LIGA_DATA,
            championshipId: '94497550-7f1a-4aef-91bf-ffcd6f67fc77',
            season: '2024-2025'
        },
        {   engTitle: 'World Cup', 
            ruTitle: 'World Cup', 
            path: '/world-cup', 
            img: SERIA_A, 
            tournamentPath: '',
            championshipId: '',
            season: ''
        },
    ]



    const dispatch = useAppDispatch()

    const fetchChampionshipData = (championship_id: string, season: string) => {
        dispatch(setCurrentChampionshipIdAndSeason({ championshipId: championship_id, season: season }))
    }


    const [activeLink, setActiveLink] = useState<number>(0)

    const { theme } = useContext(ThemeContext)
    const { language } = useContext(LanguageContext)
    const location = useLocation()

    const border = theme === 'dark' ? '1px solid #5C5C5C' : '1px solid #E1E3EA'

    const sportsDefaultValue = language === 'Eng' ? sportOptions[0].engTitle : sportOptions[0].ruTitle
    const championshipDefaultValue = language === 'Eng' ? championshipsOptions[0].engTitle : championshipsOptions[0].ruTitle

    const getChampionsLeague = () => {
        axios.get('http://localhost:3001/api/data/new_format').then((response: any) => {
            dispatch(changeTournamentType())
            dispatch(getTournamentData(response.data))
        })
    }

    return (
        <nav className={styles.navbar}>
            <div style={{ color: theme === 'dark' ? 'white' : '#333333' }}>{language === 'Eng' ? 'Football' : 'Футбол'}</div>
            {/* <CustomSelect defaultValue={sportsDefaultValue} options={sportOptions} type='routing' /> */}
            <CustomSelect defaultValue={championshipDefaultValue} options={championshipsOptions} type='routing' fetchChampionshipData={fetchChampionshipData} />
            {/* <button onClick={getChampionsLeague}>Tournament</button> */}
            <div className={styles.border} style={{ border: border }} />
            <HeaderRoutingSelect routes={routes} defaul_value={routes[0].titleEng} />
            <ul className={styles.navbar_list}>
                {
                    routes.map(route => {
                        const isRouteActive = activeLink === route.id || location.pathname === route.path;
                        const navLinkStyles = classNames({
                            [styles.NavLink]: true,
                            [styles.dark]: theme === 'dark',
                            [styles.light]: theme === 'light',
                            [styles.active]: isRouteActive
                        })

                        return (
                            <NavLink
                                key={route.id}
                                to={route.path}
                                className={navLinkStyles}
                                onClick={() => setActiveLink(route.id)}
                            >
                                {language === 'Eng' ? route.titleEng : route.titleRu}
                                <img src={activeLink === 5 ? route.img_active : route.img} alt="" />
                            </NavLink>
                        )
                    }
                    )
                }
            </ul>
        </nav>
    );
};

export default Navbar;
